import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_ROLE } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  list_role: { data: [] },
  list_role_menu: [],
  detail_role: {},
  data_modal_add_edit: {},
  toggle_modal_add_edit_discard: {
    targetModals: false,  
    title: '',
    description: '',
    type: '',
    customTitleBtnOk: '',
    customTitleBtnCancel: '',
    customClass: '',
    loadingTable: false,
    loadingPopUp: false
  },
  meta_filter: {},
  meta_filter2: {}
}

const mutations = {
  "SET_LIST_ROLE": (state, payload) => {
    Vue.set(state, 'list_role', payload)
  },
  "SET_LIST_ROLE_MENU": (state, payload) => {
    Vue.set(state, 'list_role_menu', payload)
  },
  "SET_DETAIL_ROLE": (state, payload) => {
    Vue.set(state, 'detail_role', payload)
  },
  "SET_DATA_MODAL_ADD_EDIT": (state, payload) => {
    Vue.set(state, 'data_modal_add_edit', payload)
  },
  "SET_TOGGLE_MODAL_ADD_EDIT_DISCARD": (state, payload) => {
    Vue.set(state, 'toggle_modal_add_edit_discard', payload)
  },
  "SET_META_FILTER": (state, payload) => {
    Vue.set(state, 'meta_filter', payload)
  },
  "SET_META_FILTER2": (state, payload) => {
    Vue.set(state, 'meta_filter2', payload)
  }
  
}

const actions = {
  async getListRole (context, payload) {
    try {
      const response = await apiGetAuth(API_ROLE.LIST_ROLE, payload)
      context.commit('SET_LIST_ROLE', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListMenuRole (context, payload) {
    try {
      const response = await apiGetAuth(API_ROLE.LIST_ROLE_MENU, payload)
      context.commit('SET_LIST_ROLE_MENU', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailRole (context, payload) {
    try {
      const response = await apiGetAuth(API_ROLE.DETAIL_ROLE(payload))
      context.commit('SET_DETAIL_ROLE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createRole (context, payload) {
    try {
      const response = await apiPostAuth(API_ROLE.CREATE_ROLE, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateRole (context, payload) {
    try {
      const response = await apiPutAuth(API_ROLE.UPDATE_ROLE(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteRole (context, payload) {
    try {
      const response = await apiDeleteAuth(API_ROLE.DELETE_ROLE(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_role: state => {
    return state.list_role
  },
  list_role_menu: state => {
    return state.list_role_menu
  },
  detail_role: state => {
    return state.detail_role
  },
  get_data_modal_add_edit: state => {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard: state => {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter2 (state) {
    return state.meta_filter2
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
