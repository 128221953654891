import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_HULLING } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  hulling_facility: { data: [] },
  detail_hulling: {},
  meta_filter: {},
  data_modal_add_edit: {},
  toggle_modal_add_edit_discard: {
    targetModals: false,  
    title: '',
    description: '',
    type: '',
    customTitleBtnOk: '',
    customTitleBtnCancel: '',
    customClass: '',
    loadingTable: false,
    loadingPopUp: false
  },
  list_master_data: {}
}

const mutations = {
  "SET_LIST_HULLING": (state, payload) => {
    Vue.set(state, 'hulling_facility', payload)
  },
  "SET_DETAIL_HULLING": (state, payload) => {
    Vue.set(state, 'detail_hulling', payload)
  },
  "SET_DATA_MODAL_ADD_EDIT": (state, payload) => {
    Vue.set(state, 'data_modal_add_edit', payload)
  },
  "SET_MASTER_DATA": (state, payload) => {
    Vue.set(state, 'list_master_data', payload)
  },
  "SET_TOGGLE_MODAL_ADD_EDIT_DISCARD": (state, payload) => {
    Vue.set(state, 'toggle_modal_add_edit_discard', payload)
  },
  "SET_META_FILTER": (state, payload) => {
    Vue.set(state, 'meta_filter', payload)
  }
}

const actions = {
  async getListHulling (context, payload) {
    try {
      const response = await apiGetAuth(API_HULLING.LIST_HULLING, payload)
      context.commit('SET_LIST_HULLING', response.data.data)
      context.commit('SET_META_FILTER', payload)
      return response.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailHulling (context, payload) {
    try {
      const response = await apiGetAuth(API_HULLING.DETAIL_HULLING(payload))
      context.commit('SET_DETAIL_HULLING', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createHulling (context, payload) {
    try {
      const response = await apiPostAuth(API_HULLING.CREATE_HULLING, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateHulling (context, payload) {
    try {
      const response = await apiPutAuth(API_HULLING.UPDATE_HULLING(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteHulling (context, payload) {
    try {
      const response = await apiDeleteAuth(API_HULLING.DELETE_HULLING(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_hulling: state => {
    return state.hulling_facility
  },
  detail_hulling: state => {
    return state.detail_hulling
  },
  get_data_modal_add_edit: state => {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard: state => {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
