import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_EXPORT_HUB } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  export_hub: { data: [] },
  export_hub_list_filter: [],
  detail_export: { data: {} },
  meta_filter: {},
  meta_filter2: {},
  data_modal_add_edit: {},
  toggle_modal_add_edit_discard: {
    targetModals: false,  
    title: '',
    description: '',
    type: '',
    customTitleBtnOk: '',
    customTitleBtnCancel: '',
    customClass: '',
    loadingTable: false,
    loadingPopUp: false
  }
}

const mutations = {
  "SET_LIST_EXPORT_HUB": (state, payload) => {
    Vue.set(state, 'export_hub', payload)
  },
  "SET_EXPORT_HUB_LIST_FILTER": (state, payload) => {
    Vue.set(state, 'export_hub_list_filter', payload)
  },
  "SET_DETAIL_EXPORT_HUB": (state, payload) => {
    Vue.set(state, 'detail_export', payload)
  },
  "SET_DATA_MODAL_ADD_EDIT": (state, payload) => {
    Vue.set(state, 'data_modal_add_edit', payload)
  },
  "SET_TOGGLE_MODAL_ADD_EDIT_DISCARD": (state, payload) => {
    Vue.set(state, 'toggle_modal_add_edit_discard', payload)
  },
  "SET_META_FILTER": (state, payload) => {
    Vue.set(state, 'meta_filter', payload)
  },
  "SET_META_FILTER2": (state, payload) => {
    Vue.set(state, 'meta_filter2', payload)
  }
  
}

const actions = {
  async getListExportHub (context, payload) {
    try {
      const response = await apiGetAuth(API_EXPORT_HUB.LIST_EXPORT_HUB, payload)
      context.commit('SET_LIST_EXPORT_HUB', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getExportHubListFilter (context, payload) {
    try {
      const response = await apiGetAuth(API_EXPORT_HUB.LIST_EXPORT_HUB, payload)
      context.commit('SET_EXPORT_HUB_LIST_FILTER', response.data.data)
      context.commit('SET_META_FILTER2', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createExportHub (context, payload) {
    try {
      const response = await apiPostAuth(API_EXPORT_HUB.CREATE_EXPORT_HUB, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getDetailExportHub (context, payload) {
    try {
      const response = await apiGetAuth(API_EXPORT_HUB.DETAIL_EXPORT_HUB(payload))
      context.commit('SET_DETAIL_EXPORT_HUB', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateExportHub (context, payload) {
    try {
      const response = await apiPutAuth(API_EXPORT_HUB.UPDATE_EXPORT_HUB(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteExportHub (context, payload) {
    try {
      const response = await apiDeleteAuth(API_EXPORT_HUB.DELETE_EXPORT_HUB(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_export_hub: state => {
    return state.export_hub
  },
  list_export_hub_filter: state => {
    return state.export_hub_list_filter
  },
  detail_export_hub: state => {
    return state.detail_export
  },
  get_data_modal_add_edit: state => {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard: state => {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter2 (state) {
    return state.meta_filter2
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
