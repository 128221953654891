// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'
import VeeValidate from 'vee-validate'
import App from './App'
import router from './router'
import { store } from './store'
import interceptorsSetup from '@/configs/axios'
import VueLazyload from 'vue-lazyload'
import VueCountdown from '@chenfengyuan/vue-countdown'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VeeValidate)
Vue.use(VueLazyload)
Vue.component(VueCountdown.name, VueCountdown)

interceptorsSetup()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
