import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import user from './modules/user-management/user'
import role from './modules/user-management/role'
import regions from './modules/regions'
import partner from './modules/partner'
import masterData from './modules/master-data'
import exportHub from './modules/facility/export-hub'
import hullingFacility from './modules/facility/hulling-facility'
import pulpingFacility from './modules/facility/pulping-facility'
import plantation from './modules/facility/plantation'
import pulpingActivity from './modules/activity/pulping-activity'
import hullingActivity from './modules/activity/hulling-activity'
import purchaseOrder from './modules/purchase-order'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  modules: {
    auth,
    user,
    role,
    partner,
    regions,
    masterData,
    exportHub,
    hullingFacility,
    pulpingFacility,
    plantation,
    pulpingActivity,
    hullingActivity,
    purchaseOrder
  }
})
