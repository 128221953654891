<template>
  <div
    id="modal-auth-info"
    :class="`${getToggleModalAddEditDiscard.targetModals ? 'uk-flex-top uk-modal uk-flex uk-open' : 'uk-modal'}`"
    uk-modal
    bg-close="false"
  >
    <div :class="`uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-text-center uk-modal-auth-info`">
      <div
        v-if="getToggleModalAddEditDiscard.type === 'login'"
        class="ic-send-email"
      >
        <img
          :src="`${images}/icon/ic_send_email.svg`"
          width="142"
          height="122"
          alt="send-email"
        >
      </div> 
      <div
        v-if="getToggleModalAddEditDiscard.type === 'activated-user'"
        class="ic-success"
      >
        <img
          :src="`${images}/icon/ic_success.svg`"
          width="80"
          height="80"
          alt="success"
        >
      </div>
      <div
        v-if="getToggleModalAddEditDiscard.type === 'reset-password'"
        class="ic-success"
      >
        <img
          :src="`${images}/icon/ic_success.svg`"
          width="80"
          height="80"
          alt="success"
        >
      </div> 
      <p>{{ getToggleModalAddEditDiscard.title }}</p>
      <div class="uk-text-center uk-flex uk-flex-center">
        <button
          v-if="!getToggleModalAddEditDiscard.loading"
          :class="`uk-button uk-button-primary uk-flex-center uk-width-1-1`"
          type="button"
          @click="handleButton"
        >
          {{ getToggleModalAddEditDiscard.customTitleBtnOk }}
        </button>
        <button
          v-else
          :class="`uk-button uk-button-primary uk-flex-center uk-width-1-1`"
          type="button"
          disabled
        >
          <div uk-spinner />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    images: {
      default: '',
      type: String
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'auth/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setToggleModalAddEditDiscard: 'auth/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleButton () {
      if (this.getToggleModalAddEditDiscard.type === 'activated-user') {
        this.$router.push({ name: this.getToggleModalAddEditDiscard.route , query:{ activation_token: this.getToggleModalAddEditDiscard.query1, code: this.getToggleModalAddEditDiscard.query2 }})
      } else {
        this.$router.push({ name: this.getToggleModalAddEditDiscard.route })
      }
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customClass: '',
        loading: false,
        loadingPopUp: false
      })
    }
  }
}
</script>

<style scoped>
  .uk-modal-body {
    width: 360px;
    box-shadow: 0px 8px 40px rgba(12, 50, 96, 0.05);
    border-radius: 24px;
  }
  .uk-modal-close {
    margin-right: 16px;
  }
  .uk-modal-body p {
    margin: 24px 0px;
    font-family: 'interBold';
    font-weight: 700;
    font-size: 20px;
    color: #000;
  }
</style>
