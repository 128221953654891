import { apiPostNonAuth, apiPostAuth } from '@/utils/api'
import { API_AUTH } from '@/utils/api-url'
import { setCookie, setCookieProfile, setStorageRoleInfo, logoutUser } from '@/utils/auth'
import {
  notificationSuccess,
  notificationDanger
} from '@/utils/notification'
import router from '@/router'
import Vue from 'vue'

const state = {
  loginResponse: {},
  toggle_modal_add_edit_discard: {
    targetModals: false,  
    title: '',
    description: '',
    type: '',
    customTitleBtnOk: '',
    customClass: '',
    loading: false,
    loadingPopUp: false
  }
}

const mutations = {
  "SET_LOGIN_RESPONSE": (state, payload) => {
    Vue.set(state, 'loginResponse', payload)
  },
  "SET_TOGGLE_MODAL_ADD_EDIT_DISCARD": (state, payload) => {
    Vue.set(state, 'toggle_modal_add_edit_discard', payload)
  }
}

const actions = {
  async login(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.LOGIN, payload)
      if (response.data.data?.role?.name == "Root" && response.data.data?.token == '') {
        return response.data
      } else {
        if (response.data.message == 'OK') {
          setStorageRoleInfo(response.data.data.role_management, response.data.data.role)
          setCookie(response.data.data?.token)
          const profile = {
            "name": response.data.data?.name || 'Admin Default',
            "role": response.data.data?.role?.name || 'Root'
          }
          setCookieProfile(profile)
          const filterRole = response.data.data?.role?.managements
          if(response.data.data?.role?.name != 'Super Admin') {
            localStorage.setItem('role_managements', JSON.stringify(filterRole))
          } else {
            localStorage.setItem('role_managements', JSON.stringify([]))
          }
          notificationSuccess('Login Success')
          context.commit('SET_LOGIN_RESPONSE', response.data?.data)
          setTimeout(() => {
            router.push('/admin/dashboard')
            router.go(0)
          }, 1000)
        }
      }
      
      return response.data
    } catch (err) {
      notificationDanger(err)
    }
  },
  async forgotPassword(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.FORGOT_PASSWORD, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customClass: '',
        loading: false,
        loadingPopUp: false
      })
      notificationDanger(err)
    }
  },
  async resetPassword(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.RESET_PASSWORD, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {loading: false})
      notificationDanger(err)
    }
  },
  async resetPasswordFirstLogin(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.ACTIVATED_USER, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {loading: false})
      notificationDanger(err)
    }
  },
  async verificationActivatedUser(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.ACTIVATED_USER, payload)
      return response
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {loading: false})
      notificationDanger(err)
      return err
    }
  },
  async verificationReset(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.RESET_PASSWORD, payload)
      return response
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {loading: false})
      notificationDanger(err)
      return err
    }
  },
  async logout() {
    try {
      await apiPostAuth(API_AUTH.LOGOUT)
      notificationSuccess('Logout Success')
      setTimeout(() => {
        logoutUser()
      }, 1000)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  getLoginResponse: state => {
    return state.loginResponse
  },
  getChangePassword: state => {
    return state.changePassword
  },
  get_toggle_modal_add_edit_discard: state => {
    return state.toggle_modal_add_edit_discard
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
