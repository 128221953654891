import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_PLANTATION } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  plantation_list: { data: [] },
  detail_plantation: {},
  meta_filter: {},
  data_modal_add_edit: {},
  toggle_modal_add_edit_discard: {
    targetModals: false,  
    title: '',
    description: '',
    type: '',
    customTitleBtnOk: '',
    customTitleBtnCancel: '',
    customClass: '',
    loadingTable: false,
    loadingPopUp: false
  }
}

const mutations = {
  "SET_LIST_PLANTATION": (state, payload) => {
    Vue.set(state, 'plantation_list', payload)
  },
  "SET_DETAIL_PLANTATION": (state, payload) => {
    Vue.set(state, 'detail_plantation', payload)
  },
  "SET_DATA_MODAL_ADD_EDIT": (state, payload) => {
    Vue.set(state, 'data_modal_add_edit', payload)
  },
  "SET_TOGGLE_MODAL_ADD_EDIT_DISCARD": (state, payload) => {
    Vue.set(state, 'toggle_modal_add_edit_discard', payload)
  },
  "SET_META_FILTER": (state, payload) => {
    Vue.set(state, 'meta_filter', payload)
  }
}

const actions = {
  async getListPlantation (context, payload) {
    try {
      const response = await apiGetAuth(API_PLANTATION.LIST_PLANTATION, payload)
      context.commit('SET_LIST_PLANTATION', response.data.data)
      context.commit('SET_META_FILTER', payload)
      return response.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailPlantation (context, payload) {
    try {
      const response = await apiGetAuth(API_PLANTATION.DETAIL_PLANTATION(payload))
      context.commit('SET_DETAIL_PLANTATION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createPlantation (context, payload) {
    try {
      const response = await apiPostAuth(API_PLANTATION.CREATE_PLANTATION, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updatePlantation (context, payload) {
    try {
      const response = await apiPutAuth(API_PLANTATION.UPDATE_PLANTATION(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deletePlantation (context, payload) {
    try {
      const response = await apiDeleteAuth(API_PLANTATION.DELETE_PLANTATION(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_plantation: state => {
    return state.plantation_list
  },
  detail_plantation: state => {
    return state.detail_plantation
  },
  get_data_modal_add_edit: state => {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard: state => {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
