import { apiGetNonAuth } from '@/utils/api'
import { API_MASTER_DATA } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  list_master_data: [],
  list_address_type: [],
  list_varieties_type: [],
  list_species_type: [],
  list_product_type: [],
  meta_filter: {},
  meta_filter2: {}
}

const mutations = {
  "SET_MASTER_DATA": (state, payload) => {
    Vue.set(state, 'list_master_data', payload)
  },
  "SET_ADDRESS_TYPE": (state, payload) => {
    Vue.set(state, 'list_address_type', payload)
  },
  "SET_VARIETIES_TYPE": (state, payload) => {
    Vue.set(state, 'list_varieties_type', payload)
  },
  "SET_SPECIES_TYPE": (state, payload) => {
    Vue.set(state, 'list_species_type', payload)
  },
  "SET_PRODUCT_TYPE": (state, payload) => {
    Vue.set(state, 'list_product_type', payload)
  },
  "SET_META_FILTER": (state, payload) => {
    Vue.set(state, 'meta_filter', payload)
  },
  "SET_META_FILTER2": (state, payload) => {
    Vue.set(state, 'meta_filter2', payload)
  }
}

const actions = {
  async getListMasterData (context, payload) {
    try {
      const response = await apiGetNonAuth(API_MASTER_DATA.LIST_MASTER_DATA, payload)
      context.commit('SET_MASTER_DATA', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListAddressType (context, payload) {
    try {
      const response = await apiGetNonAuth(API_MASTER_DATA.LIST_MASTER_DATA, payload)
      context.commit('SET_ADDRESS_TYPE', response.data.data)
      context.commit('SET_META_FILTER2', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListVarietiesType (context, payload) {
    try {
      const response = await apiGetNonAuth(API_MASTER_DATA.LIST_MASTER_DATA, payload)
      context.commit('SET_VARIETIES_TYPE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListSpeciesType (context, payload) {
    try {
      const response = await apiGetNonAuth(API_MASTER_DATA.LIST_MASTER_DATA, payload)
      context.commit('SET_SPECIES_TYPE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListProductType (context, payload) {
    try {
      const response = await apiGetNonAuth(API_MASTER_DATA.LIST_MASTER_DATA, payload)
      context.commit('SET_PRODUCT_TYPE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_master_data: state => {
    return state.list_master_data
  },
  list_address_type: state => {
    return state.list_address_type
  },
  list_varieties_type: state => {
    return state.list_varieties_type
  },
  list_species_type: state => {
    return state.list_species_type
  },
  list_product_type: state => {
    return state.list_product_type
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter2 (state) {
    return state.meta_filter2
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
