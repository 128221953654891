<template>
  <div class="uk-width-1-1 uk-height-1-1 bg-white">
    <div
      class="uk-child-width-1-2@s uk-child-width-1-2@m uk-text-center uk-margin-remove"
      uk-grid
    >
      <div class="section-cover-login">
        <div class="bg-login">
          <img
            :src="`${images}/bg-login.png`"
            alt="bg-login"
          >
        </div>
        <div class="uk-margin-medium-top uk-text-left">
          <h1 class="headline-login">
            Manage and monitor the process of coffee
          </h1>
        </div>
      </div>

      <div>
        <div class="section-login uk-flex-column">
          <div class="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <img
              :src="`${images}/logo-varion.png`"
              alt="logo-varion"
              width="238"
              height="58"
            >
            <h4 class="logo-text uk-margin-large-bottom">
              Admin Dashboard
            </h4>
          </div>
          <form
            class="uk-form-auth"
            @submit.prevent="onSubmit"
          >
            <fieldset class="uk-fieldset uk-text-left">
              <h3 class="uk-legend">
                Login
              </h3>
              <div class="uk-margin uk-text-left">
                <div class="uk-inline uk-width-1-1">
                  <label>Email</label>
                  <input
                    v-model="email"
                    v-validate="'required|email'"
                    name="email"
                    class="uk-input"
                    type="text"
                    autocomplete="on"
                    placeholder="Enter Email"
                    :class="{'uk-form-danger': errors.has('email')}"
                  >
                </div>
                <span
                  v-show="errors.has('email')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('email') }}</span>
              </div>
            
              <div class="uk-margin uk-text-left parent-password-form">
                <div class="uk-inline uk-width-1-1">
                  <img
                    v-if="isVisible"
                    class="uk-form-icon-custom uk-form-icon-flip"
                    :src="`${images}/icon/ic_eye_on.svg`"
                    alt="eye-on-icon"
                    @click="isVisible = !isVisible"
                  >
                  <img
                    v-else
                    class="uk-form-icon-custom uk-form-icon-flip"
                    :src="`${images}/icon/ic_eye_off.svg`"
                    alt="eye-off-icon"
                    @click="isVisible = !isVisible"
                  >
                  <label>Password</label>
                  <input
                    v-model="password"
                    v-validate="'required|min:8'"
                    name="password"
                    class="uk-input"
                    :type="isVisible ? 'password' : 'text'"
                    placeholder="Enter Password"
                    autocomplete="on"
                    :class="{'uk-form-danger': errors.has('password')}"
                  >
                </div>
                <span
                  v-show="errors.has('password')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('password') }}</span>
              </div>
            
              <router-link to="/forgot-password">
                <h4 class="uk-text-right">
                  Forgot Password
                </h4>
              </router-link>

              <button
                v-if="!getToggleModalAddEditDiscard.loading"
                class="uk-button uk-button-primary uk-width-1-1"
                type="submit"
              >
                Login
              </button>
              <button
                v-else
                class="uk-button uk-button-primary uk-width-1-1"
                type="button"
                disabled
              >
                <div uk-spinner />
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <modal-auth-info
      :images="images"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import ModalAuthInfo from '@/components/globals/modals/ModalAuthInfo'

export default {
  components: {
    ModalAuthInfo
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isVisible: true,
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'auth/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapActions({
      loginUser: 'auth/login'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'auth/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.login()
        }
      })
    },
    async login() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading:true })
      await this.loginUser({email: this.email, password: this.password})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading:false })
    }
  }
}
</script>

<style scoped>
  .uk-margin.uk-text-left {
    margin-top: 24px !important;
  }
  h3 {
    font-family: 'interBold';
    font-weight: 700;
    font-size: 32px;
    color: #000000;
  }
  h4 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  label {
    display: inline-block;
    margin-bottom: 5px;
    font-family: 'interRegular';
    font-size: 16px !important;
    color: #000000 !important;
  }
  .uk-legend {
    margin-bottom: 0;
  }
  .uk-form-icon-custom {
    position: absolute;
    top: 45px;
    width: 24px;
    height: 24px;
    right: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #999;
    cursor: pointer;
  }
  .uk-margin.uk-text-right span {
    color: #E93535;
    font-size: 14px;
  }
  .uk-margin.uk-text-right span, .uk-button.red span{
    font-family: 'jakartaSemiBold';
    font-weight: 600;
    cursor: pointer;
  }
  .uk-button.red span {
    font-size: 16px;
  }
  .uk-button {
    display: flex;
    justify-content: center;
  }
  .uk-first-column {
    padding-left: 0;
  }
  .parent-password-form {
    position: relative;
  }
</style>
