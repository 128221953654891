import * as Cookies from 'js-cookie'
import router from '@/router'

export const setCookie = (cookie) => {
  Cookies.set('varion-token', cookie, { path: '/' })
  return {
    token: Cookies.get('varion-token')
  }
}

export const setCookieProfile = (cookie) => {
  Cookies.set('varion-profile', cookie, { path: '/' })
  return {
    profile: Cookies.get('varion-profile')
  }
}

export const getUserToken = () => {
  return Cookies.get('varion-token')
}

export const getUserProfile = () => {
  return JSON.parse(Cookies.get('varion-profile'))
}

export const getDataRole = () => {
  return JSON.parse(localStorage.getItem('role_managements'))
}

export const setStorageRoleInfo = (role_management, role) => {
  if (role.Name == 'Super Admin') {
    role_management = []
  }
  const obj = {
    role_management : role_management,
    role : role
  }
  localStorage.setItem('varion-role-info', JSON.stringify(obj))
  return localStorage.getItem('varion-role-info')
}

export const isLogin = () => {
  const token = Cookies.get('varion-token')
  // const profile = Cookies.get('varion-profile')
  if (token) {
    return true
  } 
  return false
}

export const isCanAccess = (type, menu) => {
  const userRole = JSON.parse(localStorage.getItem('varion-role-info'))
  let result = false
  if (userRole.role.name === `Super Admin`) {
    result = true
  } else {
    const findAccess = userRole.role.managements.find(x => x.name.toLowerCase() == menu.toLowerCase())
    if (findAccess) {
      result = findAccess[type]
    }
  }
  return result
}

export const logoutUser = () => {
  Cookies.remove('varion-token', { path: '/' })
  Cookies.remove('varion-profile', { path: '/' })
  localStorage.clear()
  router.go(0)
}
