const METHOD = {
  GET: 'get',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
}

const PREFIX_IMAGE = '/images'

const URL_IMAGE = `${process.env.VUE_APP_IMAGE_URL}`

const STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  VERIFIED: 'Verified',
  UNVERIFIED: 'Unverified'
}

const STATUS_FILTER = [
  { value: null, name: 'All' },
  { value: 1, name: 'Active' },
  { value: 0, name: 'Inactive' }
]

const WORDING = {
  CANCEL: 'Cancel',
  SAVE: 'Save'
}

export {
  METHOD,
  PREFIX_IMAGE,
  STATUS,
  STATUS_FILTER,
  WORDING,
  URL_IMAGE
}
