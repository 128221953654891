const BASE_URL = `${process.env.VUE_APP_API_URL}/dashboard`

// Auth //
export const API_AUTH = {
  LOGIN: `${BASE_URL}/auth/login`,
  LOGOUT: `${BASE_URL}/auth/logout`,
  ACTIVATED_USER: `${BASE_URL}/auth/activate-user`,
  FORGOT_PASSWORD: `${BASE_URL}/auth/forgot-password`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`
}

// User //
export const API_USER = {
  LIST_USER: `${BASE_URL}/users`,
  CREATE_USER: `${BASE_URL}/users`,
  DETAIL_USER: id => `${BASE_URL}/users/${id}`,
  UPDATE_USER: id => `${BASE_URL}/users/${id}`,
  DELETE_USER: id => `${BASE_URL}/users/${id}`,
  EMAIL_VERIFICATION: id => `${BASE_URL}/users/resend-activation-email/${id}`,
  LIST_USER_FACILITY: `${BASE_URL}/user-facilities`
}

// Role //
export const API_ROLE = {
  LIST_ROLE: `${BASE_URL}/role`,
  CREATE_ROLE: `${BASE_URL}/role/create`,
  DETAIL_ROLE: id => `${BASE_URL}/role/detail/${id}`,
  UPDATE_ROLE: id => `${BASE_URL}/role/${id}`,
  DELETE_ROLE: id => `${BASE_URL}/role/${id}`,
  LIST_ROLE_MENU: `${BASE_URL}/role/role-management-menu`
}

// Partner / Farmer //
export const API_PARTNER = {
  LIST_PARTNER: `${BASE_URL}/partner`,
  LIST_ADDRESS_PARTNER: `${BASE_URL}/partner/partner-addresses`,
  CREATE_PARTNER: `${BASE_URL}/partner/create`,
  DETAIL_PARTNER: id => `${BASE_URL}/partner/detail/${id}`,
  UPDATE_PARTNER: id => `${BASE_URL}/partner/${id}`,
  DELETE_PARTNER: id => `${BASE_URL}/partner/${id}`,
  LIST_PARTNER_FACILITY: `${BASE_URL}/partner/partner-facilities`
}

// Regions //
export const API_REGIONS = {
  LIST_REGIONS: `${BASE_URL}/regions`
}

// Master Data //
export const API_MASTER_DATA = {
  LIST_MASTER_DATA: `${BASE_URL}/master-data`
}

// Export Hub //
export const API_EXPORT_HUB = {
  LIST_EXPORT_HUB: `${BASE_URL}/export-hub`,
  CREATE_EXPORT_HUB: `${BASE_URL}/export-hub/create`,
  DETAIL_EXPORT_HUB: id => `${BASE_URL}/export-hub/${id}`,
  UPDATE_EXPORT_HUB: id => `${BASE_URL}/export-hub/${id}`,
  DELETE_EXPORT_HUB: id => `${BASE_URL}/export-hub/${id}`
}

// Hulling Facility //
export const API_HULLING = {
  LIST_HULLING: `${BASE_URL}/hulling-facility`,
  CREATE_HULLING: `${BASE_URL}/hulling-facility/create`,
  DETAIL_HULLING: id => `${BASE_URL}/hulling-facility/${id}`,
  UPDATE_HULLING: id => `${BASE_URL}/hulling-facility/${id}`,
  DELETE_HULLING: id => `${BASE_URL}/hulling-facility/${id}`
}

// Pulping Facility //
export const API_PULPING = {
  LIST_PULPING: `${BASE_URL}/pulping-facility`,
  CREATE_PULPING: `${BASE_URL}/pulping-facility/create`,
  DETAIL_PULPING: id => `${BASE_URL}/pulping-facility/${id}`,
  UPDATE_PULPING: id => `${BASE_URL}/pulping-facility/${id}`,
  DELETE_PULPING: id => `${BASE_URL}/pulping-facility/${id}`
}

// Plantation //
export const API_PLANTATION = {
  LIST_PLANTATION: `${BASE_URL}/plantation`,
  CREATE_PLANTATION: `${BASE_URL}/plantation/create`,
  DETAIL_PLANTATION: id => `${BASE_URL}/plantation/${id}`,
  UPDATE_PLANTATION: id => `${BASE_URL}/plantation/${id}`,
  DELETE_PLANTATION: id => `${BASE_URL}/plantation/${id}`
}

// Pulping Activity
export const API_ACTIVITY = {
  LIST_PULPING_ACTIVITY: `${BASE_URL}/pulping-activity`,
  DETAIL_PULPING_ACTIVITY: id  => `${BASE_URL}/pulping-activity/detail/${id}`,
  LIST_HULLING_ACTIVITY: `${BASE_URL}/hulling-activity`,
  DETAIL_HULLING_ACTIVITY: id  => `${BASE_URL}/hulling-activity/detail/${id}`
}

// Purchase Order
export const API_PO = {
  PO: `${BASE_URL}/purchase-order`,
  PO_FARMER: `${BASE_URL}/purchase-order-farmer`
}
