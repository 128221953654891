import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_PULPING } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  pulping_facility: { data: [] },
  detail_pulping: {},
  meta_filter: {},
  data_modal_add_edit: {},
  toggle_modal_add_edit_discard: {
    targetModals: false,  
    title: '',
    description: '',
    type: '',
    customTitleBtnOk: '',
    customTitleBtnCancel: '',
    customClass: '',
    loadingTable: false,
    loadingPopUp: false
  }
}

const mutations = {
  "SET_LIST_PULPING": (state, payload) => {
    Vue.set(state, 'pulping_facility', payload)
  },
  "SET_DETAIL_PULPING": (state, payload) => {
    Vue.set(state, 'detail_pulping', payload)
  },
  "SET_DATA_MODAL_ADD_EDIT": (state, payload) => {
    Vue.set(state, 'data_modal_add_edit', payload)
  },
  "SET_TOGGLE_MODAL_ADD_EDIT_DISCARD": (state, payload) => {
    Vue.set(state, 'toggle_modal_add_edit_discard', payload)
  },
  "SET_META_FILTER": (state, payload) => {
    Vue.set(state, 'meta_filter', payload)
  }
}

const actions = {
  async getListPulping (context, payload) {
    try {
      const response = await apiGetAuth(API_PULPING.LIST_PULPING, payload)
      context.commit('SET_LIST_PULPING', response.data.data)
      context.commit('SET_META_FILTER', payload)
      return response.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailPulping (context, payload) {
    try {
      const response = await apiGetAuth(API_PULPING.DETAIL_PULPING(payload))
      context.commit('SET_DETAIL_PULPING', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createPulping (context, payload) {
    try {
      const response = await apiPostAuth(API_PULPING.CREATE_PULPING, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updatePulping (context, payload) {
    try {
      const response = await apiPutAuth(API_PULPING.UPDATE_PULPING(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deletePulping (context, payload) {
    try {
      const response = await apiDeleteAuth(API_PULPING.DELETE_PULPING(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_pulping: state => {
    return state.pulping_facility
  },
  detail_pulping: state => {
    return state.detail_pulping
  },
  get_data_modal_add_edit: state => {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard: state => {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
