import Vue from 'vue'
import Router from 'vue-router'
import { isLogin } from '@/utils/auth'

import EmptyPageLayout from '@/components/layouts/EmptyPage'
import Login from '@/components/pages/Login'

Vue.use(Router)

const router =  new Router({
  base: '',
  mode: 'history', // remove # in url
  routes: [
    {
      path: '/',
      component: EmptyPageLayout,
      redirect: '/login',
      children: [
        {
          path: '/login',
          name: 'Login',
          component: Login,
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        },
        {
          path: '/forgot-password',
          name: 'ForgotPassword',
          component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/ForgotPassword'),
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        },
        {
          path: '/reset-password',
          name: 'ResetPassword',
          component: () => import(/* webpackChunkName: "reset-password" */ '@/components/pages/ResetPassword'),
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        },
        {
          path: '/email-verification',
          name: 'EmailVerification',
          component: () => import(/* webpackChunkName: "email-verification" */ '@/components/pages/EmailVerification'),
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        }
      ]
    },
    {
      path: '/admin',
      component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
      redirect: '/admin/dashboard',
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/components/pages/admin/dashboard/index')
        },
        // EXPORT HUB
        {
          path: 'export-hub',
          name: 'ExportHub',
          component: () => import(/* webpackChunkName: "export-hub" */ '@/components/pages/admin/facility/export-hub/index')
        },
        {
          path: 'export-hub/create',
          name: 'ExportHubCreate',
          component: () => import(/* webpackChunkName: "export-hub-create" */ '@/components/pages/admin/facility/export-hub/create/index')
        },
        {
          path: 'export-hub/detail/:id',
          name: 'ExportHubDetail',
          props: true,
          component: () => import(/* webpackChunkName: "export-hub-detail" */ '@/components/pages/admin/facility/export-hub/detail/index')
        },
        {
          path: 'export-hub/edit/:id',
          name: 'ExportHubEdit',
          props: true,
          component: () => import(/* webpackChunkName: "export-hub-edit" */ '@/components/pages/admin/facility/export-hub/edit/index')
        },
        // HULLING FACILITY
        {
          path: 'hulling-facility',
          name: 'HullingFacility',
          component: () => import(/* webpackChunkName: "hulling-facility" */ '@/components/pages/admin/facility/hulling-facility/index')
        },
        {
          path: 'hulling-facility/create',
          name: 'HullingFacilityCreate',
          component: () => import(/* webpackChunkName: "hulling-facility-create" */ '@/components/pages/admin/facility/hulling-facility/create/index')
        },
        {
          path: 'hulling-facility/detail/:id',
          name: 'HullingFacilityDetail',
          props: true,
          component: () => import(/* webpackChunkName: "hulling-facility-detail" */ '@/components/pages/admin/facility/hulling-facility/detail/index')
        },
        {
          path: 'hulling-facility/edit/:id',
          name: 'HullingFacilityEdit',
          props: true,
          component: () => import(/* webpackChunkName: "hulling-facility-edit" */ '@/components/pages/admin/facility/hulling-facility/edit/index')
        },
        // PULPING FACILITY
        {
          path: 'pulping-facility',
          name: 'PulpingFacility',
          component: () => import(/* webpackChunkName: "pulping-facility" */ '@/components/pages/admin/facility/pulping-facility/index')
        },
        {
          path: 'pulping-facility/create',
          name: 'PulpingFacilityCreate',
          component: () => import(/* webpackChunkName: "pulping-facility-create" */ '@/components/pages/admin/facility/pulping-facility/create/index')
        },
        {
          path: 'pulping-facility/detail/:id',
          name: 'PulpingFacilityDetail',
          props: true,
          component: () => import(/* webpackChunkName: "pulping-facility-detail" */ '@/components/pages/admin/facility/pulping-facility/detail/index')
        },
        {
          path: 'pulping-facility/edit/:id',
          name: 'PulpingFacilityEdit',
          props: true,
          component: () => import(/* webpackChunkName: "pulping-facility-edit" */ '@/components/pages/admin/facility/pulping-facility/edit/index')
        },
        // PLANTATION
        {
          path: 'plantation',
          name: 'Plantation',
          component: () => import(/* webpackChunkName: "plantation" */ '@/components/pages/admin/facility/plantation/index')
        },
        {
          path: 'plantation/create',
          name: 'PlantationCreate',
          component: () => import(/* webpackChunkName: "plantation-create" */ '@/components/pages/admin/facility/plantation/create/index')
        },
        {
          path: 'plantation/detail/:id',
          name: 'PlantationDetail',
          props: true,
          component: () => import(/* webpackChunkName: "plantation-detail" */ '@/components/pages/admin/facility/plantation/detail/index')
        },
        {
          path: 'plantation/edit/:id',
          name: 'PlantationEdit',
          props: true,
          component: () => import(/* webpackChunkName: "plantation-edit" */ '@/components/pages/admin/facility/plantation/edit/index')
        },
        // PARTNER
        {
          path: 'partner',
          name: 'Partner',
          component: () => import(/* webpackChunkName: "partner" */ '@/components/pages/admin/partner/index')
        },
        {
          path: 'partner/create',
          name: 'PartnerCreate',
          component: () => import(/* webpackChunkName: "partner-create" */ '@/components/pages/admin/partner/create/index')
        },
        {
          path: 'partner/detail/:id',
          name: 'PartnerDetail',
          props: true,
          component: () => import(/* webpackChunkName: "partner-detail" */ '@/components/pages/admin/partner/detail/index')
        },
        {
          path: 'partner/edit/:id',
          name: 'PartnerEdit',
          props: true,
          component: () => import(/* webpackChunkName: "partner-edit" */ '@/components/pages/admin/partner/edit/index')
        },
        // SUPPLIER
        {
          path: 'supplier',
          name: 'Supplier',
          component: () => import(/* webpackChunkName: "supplier" */ '@/components/pages/admin/supplier/index')
        },
        // Pulping Activity
        {
          path: 'pulping-activity',
          name: 'PulpingActivity',
          component: () => import(/* webpackChunkName: "pulping-activity" */ '@/components/pages/admin/activity/pulping-activity/index')
        },
        {
          path: 'pulping-activity/detail/:id',
          name: 'PulpingActivityDetail',
          component: () => import(/* webpackChunkName: "pulping-activity-detail" */ '@/components/pages/admin/activity/pulping-activity/detail/index')
        },
        {
          path: 'hulling-activity',
          name: 'HullingActivity',
          component: () => import(/* webpackChunkName: "hulling-activity" */ '@/components/pages/admin/activity/hulling-activity/index')
        },
        {
          path: 'hulling-activity/detail/:id',
          name: 'HullingActivityDetail',
          component: () => import(/* webpackChunkName: "hulling-activity-detail" */ '@/components/pages/admin/activity/hulling-activity/detail/index')
        },
        // Purchase Order
        {
          path: 'purchase-order-farmer',
          name: 'PurchaseOrderFarmer',
          component: () => import(/* webpackChunkName: "purchase-order-farmer" */ '@/components/pages/admin/purchase-order/farmer/index')
        },
        {
          path: 'purchase-order-farmer/create',
          name: 'PurchaseOrderFarmerCreate',
          component: () => import(/* webpackChunkName: "purchase-order-farmer-create" */ '@/components/pages/admin/purchase-order/farmer/create/index')
        },
        {
          path: 'purchase-order-farmer/detail/:id',
          name: 'PurchaseOrderFarmerDetail',
          component: () => import(/* webpackChunkName: "purchase-order-farmer-detail" */ '@/components/pages/admin/purchase-order/farmer/detail/index')
        },
        // USER
        {
          path: 'user',
          name: 'User',
          component: () => import(/* webpackChunkName: "user" */ '@/components/pages/admin/user/index')
        },
        {
          path: 'user/create',
          name: 'UserCreate',
          component: () => import(/* webpackChunkName: "user-create" */ '@/components/pages/admin/user/create/index')
        },
        {
          path: 'user/detail/:id',
          name: 'UserDetail',
          props: true,
          component: () => import(/* webpackChunkName: "user-detail" */ '@/components/pages/admin/user/detail/index')
        },
        {
          path: 'user/edit/:id',
          name: 'UserEdit',
          props: true,
          component: () => import(/* webpackChunkName: "user-edit" */ '@/components/pages/admin/user/edit/index')
        },
        // Role
        {
          path: 'role',
          name: 'Role',
          component: () => import(/* webpackChunkName: "role" */ '@/components/pages/admin/role/index')
        },
        {
          path: 'role/create',
          name: 'RoleCreate',
          component: () => import(/* webpackChunkName: "role-create" */ '@/components/pages/admin/role/create/index')
        },
        {
          path: 'role/detail/:id',
          name: 'RoleDetail',
          props: true,
          component: () => import(/* webpackChunkName: "role-detail" */ '@/components/pages/admin/role/detail/index')
        },
        {
          path: 'role/edit/:id',
          name: 'RoleEdit',
          props: true,
          component: () => import(/* webpackChunkName: "role-edit" */ '@/components/pages/admin/role/edit/index')
        },
        {
          path: 'unauthorized',
          name: 'Unauthorized',
          component: () => import(/* webpackChunkName: "unauthorized" */ '@/components/pages/Unauthorized')
        }
      ]
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "not-found" */ '@/components/pages/NotFound'),
      meta: {
        public: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = isLogin()
  // let userRole;

  // if(loggedIn){
  //   userRole = getUserRole()
  // }

  // to.matched.map(record => {
  //   if (record.meta.roles === undefined) {
  //     next()
  //   }else{
  //     if(record.meta.roles.some(item => item == userRole)){
  //       next()
  //     }else{
  //       next("/admin/unauthorized")
  //     }
  //   }
  // })

  if (!isPublic && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/admin')
  }

  next()
})

export default router
