import { apiGetNonAuth } from '@/utils/api'
import { API_REGIONS } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  list_regions: [],
  meta: {}
}

const mutations = {
  "SET_LIST_REGIONS": (state, payload) => {
    Vue.set(state, 'list_regions', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  }
}

const actions = {
  async getListRegions (context, payload) {
    try {
      const response = await apiGetNonAuth(API_REGIONS.LIST_REGIONS, payload)
      context.commit('SET_LIST_REGIONS', response.data.data)
      context.commit('SET_META', payload)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_regions: state => {
    return state.list_regions
  },
  meta (state) {
    return state.meta
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
