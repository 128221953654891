import { apiGetAuth, apiPostAuth } from '@/utils/api'
import { API_PO } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'
  
const state = {
  list_po: [],
  meta: {},
  data_modal_add_edit: {},
  toggle_modal_add_edit_discard: {
    targetModals: false,  
    title: '',
    description: '',
    type: '',
    customTitleBtnOk: '',
    customTitleBtnCancel: '',
    customClass: '',
    loadingTable: false,
    loadingPopUp: false
  }
}

const mutations = {
  "SET_LIST_PO": (state, payload) => {
    Vue.set(state, 'list_po', payload)
  },
  "SET_DETAIL_PO_FARMER": (state, payload) => {
    Vue.set(state, 'dataPoFarmerDetail', payload)
  },
  "SET_LIST_FARMER": (state, payload) => {
    Vue.set(state, 'list_farmer', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_DATA_MODAL_ADD_EDIT": (state, payload) => {
    Vue.set(state, 'data_modal_add_edit', payload)
  },
  "SET_TOGGLE_MODAL_ADD_EDIT_DISCARD": (state, payload) => {
    Vue.set(state, 'toggle_modal_add_edit_discard', payload)
  }
}
  
const actions = {
  async getListPo (context, payload) {
    try {
      const response = await apiGetAuth(API_PO.PO_FARMER, payload)
      context.commit('SET_LIST_PO', response.data.data)
      context.commit('SET_META', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListFarmerList(context, payload) {
    try {
      const response = await apiGetAuth(API_PO.PO+'/plantations', payload)
      context.commit('SET_LIST_FARMER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createPoFarmer(context, payload) {
    try {
      const response = await apiPostAuth(API_PO.PO_FARMER+'/create', payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '',
        description: '',
        type: '',
        customTitleBtnOk: '',
        customTitleBtnCancel: '',
        customClass: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getPoFarmerDetail(context, payload) {
    try {
      const response = await apiGetAuth(API_PO.PO_FARMER+'/detail/'+payload)
      context.commit('SET_DETAIL_PO_FARMER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}
  
const getters = {
  list_po: state => {
    return state.list_po
  },
  dataPoFarmerDetail: state => {
    return state.dataPoFarmerDetail
  },
  list_farmer: state => {
    return state.list_farmer
  },
  meta (state) {
    return state.meta
  },
  get_data_modal_add_edit: state => {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard: state => {
    return state.toggle_modal_add_edit_discard
  }
}
  
const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
