import { apiGetAuth } from '@/utils/api'
import { API_ACTIVITY, API_MASTER_DATA } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'
import Vue from 'vue'

const state = {
  hulling_activity: { data: [] },
  detail_hulling: {},
  meta_filter: {}
}

const mutations = {
  "SET_META_FILTER": (state, payload) => {
    Vue.set(state, 'meta_filter', payload)
  },
  "SET_LIST_SPECIES": (state, payload) => {
    Vue.set(state, 'list_species', payload)
  },
  "SET_LIST_PROCESS": (state, payload) => {
    Vue.set(state, 'list_process_type', payload)
  },
  "SET_LIST_HULLING_PROCESS_STAGES": (state, payload) => {
    Vue.set(state, 'list_hulling_process_stages', payload)
  },
  "SET_LIST_HULLING": (state, payload) => {
    Vue.set(state, 'hulling_facility', payload)
  },
  "SET_DETAIL_HULLING": (state, payload) => {
    Vue.set(state, 'detail_hulling', payload)
  }
}

const actions = {
  async getListFromMasterData(context, payload){
    try {
      const response = await apiGetAuth(API_MASTER_DATA.LIST_MASTER_DATA, payload)
      if(payload.data_type == 'species'){
        context.commit('SET_LIST_SPECIES', response.data.data)
      } else if(payload.data_type == 'process_types'){
        context.commit('SET_LIST_PROCESS', response.data.data)
      } else {
        context.commit('SET_LIST_HULLING_PROCESS_STAGES', response.data.data)
      }
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListHulling (context, payload) {
    try {
      const response = await apiGetAuth(API_ACTIVITY.LIST_HULLING_ACTIVITY, payload)
      context.commit('SET_LIST_HULLING', response.data.data)
      context.commit('SET_META_FILTER', payload)
      return response.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailHulling (context, payload) {
    try {
      const filteredPayload = Object.fromEntries(Object.entries(payload).filter(([key]) => key !== 'id'))
      const response = await apiGetAuth(API_ACTIVITY.DETAIL_HULLING_ACTIVITY(payload.id), filteredPayload)
      context.commit('SET_DETAIL_HULLING', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_hulling: state => {
    return state.hulling_facility
  },
  list_species: state => {
    return state.list_species
  },
  list_process_type: state => {
    return state.list_process_type
  },
  list_hulling_process_stages: state => {
    return state.list_hulling_process_stages
  },
  detail_hulling: state => {
    return state.detail_hulling
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
